import axios from "axios";
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/HAUTIER-logo.png';
import Title from '../Containers/Title';
import Truck from '../Truck/Truck';
import PopupMessage from "../Containers/PopupMessage";


export default function Login({ url }) {

    const [login, setLogin] = useState('');
    const [password, setpassword] = useState('');
    const [message, setMessage] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setLogin(username);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setpassword(password);
      };

    const handleLogin = () => {

        const loginData = {
          "login": login,
          "password": password
        }
        
        const api = axios.create({
          baseURL: url
        })
    
        api.post("/auth/signIn", loginData)
        
            .then(res => {           
              localStorage.setItem("token", JSON.stringify(res.data));
              
              navigate("/accueil/"+res.data.user.idUser);
            })
            .catch(error => {
                console.log(error)
                    setMessage("Erreur dans le login ou le mot de passe! Veuillez réessayer."); 
               
                setShowPopup(true);
            });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
        }
    };

    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div>
                <img className="w-[20%]" src={logo} alt="logo" />
            </div>
            <Truck />
            <div className="w-full p-6 m-auto bg-white -mt-2  lg:max-w-xl">
                <Title title="Connexion"/>
                <form  className="mt-6" onKeyDown={handleKeyPress}>
                    <div className="mb-2">
                        <label
                            className="block text-sm font-semibold text-grey"
                        >
                            Nom d'utilisateur
                        </label>
                        <input
                            type="text"
                            name="login"
                            className="block w-full px-4 py-2 mt-2 text-marineLogo bg-white border rounded-md focus:border-purple-400 focus:ring-bleu200 focus:outline-none focus:ring focus:ring-opacity-40"
                            value={login} 
                            onChange={onChangeUsername}
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            className="block text-sm font-semibold text-grey"
                        >
                            Mot de passe
                        </label>
                        <input
                            type="password"
                            name="password"
                            className="block w-full px-4 py-2 mt-2 text-marineLogo bg-white border rounded-md focus:border-marineLogo focus:ring-bleu200 focus:outline-none focus:ring focus:ring-opacity-40"
                            value={password}
                            onChange={onChangePassword}
                        />
                    </div>
                    <Link
                        to="/mdpForget"
                        className="text-xs text-marineLogo hover:underline"
                    >
                        Mot de passe oublié?
                    </Link>
                    <div className="mt-6">
                        <button type="button" className="w-full px-4 py-2 tracking-wide text-blanc transition-colors duration-200 transform bg-vertLogo rounded-md hover:border hover:bg-blanc hover:text-vertLogo hover:border-vertLogo focus:outline-none focus:bg-blanc focus:text-vertLogo" onClick={handleLogin}>
                            Connexion
                        </button>
                    </div>
                </form>

                <p className="mt-8 text-xs font-light text-center text-grey">
                    {" "}
                    Pas encore de compte?{" "}
                    <Link
                        to="/inscription"
                        className="font-medium text-marineLogo hover:underline"
                    >
                        S'inscrire
                    </Link>
                </p>
            </div>
            <PopupMessage 
                message={message} 
                type="error"
                show={showPopup} 
                onClose={() => setShowPopup(false)} 
            />
        </div>
    );
}